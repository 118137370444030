import styled from 'styled-components';

export const types = ['default', 'category', 'teaser', 'tabs', 'service'];

export const IconStyled = styled.span.attrs(props => ({
	height: props.height || '1.15em',
	width: props.width || '1.15em'
}))`
	pointer-events: none;
	line-height: 1;
	user-select: none;

	svg {
		/* display: inline; */
		overflow: hidden;
		vertical-align: -0.15em;
		// todo: fix ie11 but this is causing even more problems
		//height: 100%;
		height: ${props => props.height};
		width: ${props => props.width};
		fill: currentColor;
	}
`;
