import {HIDE_SURVEY, SUBMIT_SURVEY_FAILED, SUBMIT_SURVEY_REQUEST, SUBMIT_SURVEY_SUCCESS} from '../constants';

export const hideSurvey = () => ({
	type: HIDE_SURVEY,
});

export const submitSurvey = (data) => ({
	type: SUBMIT_SURVEY_REQUEST,
	payload: {
		data,
	},
});

export const submitSurveySuccess = (info) => ({
	type: SUBMIT_SURVEY_SUCCESS,
	payload: {
		info,
	},
});

export const submitSurveyFailed = (error) => ({
	type: SUBMIT_SURVEY_FAILED,
	payload: {
		error,
	},
});
