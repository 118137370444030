import * as Constants from '../../constants';
import {omit} from '../../../utils/immutability';

const messages = (state = {}, action) => {
	switch (action.type) {
		case Constants.USER_LOGIN_FAILED: {
			const {error} = action.payload;
			return {
				...omit(state),
				error,
			};
		}
		case Constants.USER_LOGOUT_FAILED: {
			const {error} = action.payload;
			return {
				...omit(state),
				error,
			};
		}
		case Constants.CLEAR_ERRORS: {
			return omit(state, ['error', 'info']);
		}
		case Constants.USER_REGISTER_SUCCESS:
			return omit(state, ['error', 'info']);

		case Constants.USER_REGISTER_FAILED: {
			const {error} = action.payload;
			return {...omit(state), error: {text: error.message, button: 'OK'}};
		}

		case Constants.USER_DATA_UPDATE_SUCCESS: {
			const {info} = action.payload;
			let infoText = '';
			if (info && info.message) {
				infoText = info.message;
			}
			return {
				...omit(state, ['error']),
				info: {
					text: infoText,
					button: 'OK',
				},
			};
		}

		case Constants.USER_PASSWORD_REQUEST_SUCCESS: {
			const {info} = action.payload;
			let infoText = '';
			if (info && info.message) {
				infoText = info.message;
			}
			return {
				...omit(state, ['error']),
				info: {
					text: infoText,
					button: 'OK',
				},
			};
		}

		case Constants.USER_PASSWORD_RESET_SUCCESS: {
			const {info} = action.payload;
			let infoText = '';
			if (info && info.message) {
				infoText = info.message;
			}
			return {
				...omit(state, ['error']),
				info: {
					text: infoText,
					button: 'OK',
				},
			};
		}

		case Constants.USER_PASSWORD_RESET_FAILED: {
			const {error} = action.payload;
			let errorText = '';
			if (error && error.message) {
				errorText = error.message;
			}
			return {
				...omit(state),
				error: {
					text: errorText,
					button: 'OK',
				},
			};
		}

		case Constants.USER_DATA_UPDATE_FAILED: {
			const {error} = action.payload;
			let errorText = '';
			if (error && error.message) {
				errorText = error.message;
			}
			return {
				...omit(state),
				error: {
					text: errorText,
					button: 'OK',
				},
			};
		}

		case Constants.USER_DATA_NOTIFY: {
			const {message} = action.payload;
			return {
				...state,
				error: {
					text: message,
					button: 'OK',
				},
			};
		}

		case Constants.SUBMIT_CONTACT_SUCCESS:
		case Constants.SUBMIT_CALLBACKSERVICE_SUCCESS: {
			const {info} = action.payload;
			return {
				...omit(state, ['error']),
				info: {
					text: info,
					button: 'OK',
				},
			};
		}

		case Constants.SUBMIT_CONTACT_FAILED:
		case Constants.SUBMIT_ENROLLER_FAILED:
		case Constants.SUBMIT_ENROLLER_SUCCESS:
		case Constants.SUBMIT_CALLBACKSERVICE_FAILED: {
			const {error} = action.payload;
			return {
				...omit(state),
				error: {
					text: error,
					button: 'OK',
				},
			};
		}

		case Constants.USER_NLF_SUCCESS: {
			const {error} = action.payload;
			return {
				...state,
				error: {
					text: error.message,
					button: error.button,
				},
			};
		}

		case Constants.NOTIFY_USER: {
			const {error} = action.payload;
			return {...state, error};
		}

		default:
			return state;
	}
};

export default messages;
