const parseJson = response => {
	// re-raise an new error, based on a string (which is expected in <ErrorPanel>)
	const errorHandler = e => {
		throw `"${e.message}", trat auf während des Parsens des Suchergebnisses.`;
	};
	return response.json().catch(errorHandler);
};

export const getAllLikes = async () => {
	const allLikes = await fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/allLikes`);
	const allLikesParsed = await allLikes.json();
	return allLikesParsed;
};

export const getLikeById = id => {
	const payload = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	};
	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/likes/${id}`, payload)
		.then(parseJson)
		.then(json => json);
};

export const postLike = id => {
	const data = {post_id: id};
	const payload = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	};
	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/like`, payload)
		.then(parseJson)
		.then(json => json);
};

export const postUnlike = id => {
	const data = {post_id: id};
	const payload = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	};
	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/unlike`, payload)
		.then(parseJson)
		.then(json => json);
};
