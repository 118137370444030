import _ from 'lodash';

import {dictFacetKeyToTitle} from '../../../components/Container/Search/FullSearch/constants';
import * as Constants from '../../constants';
import {mergeUrls, urlsAndResultsCountFromAction, urlsFromState} from './convertingForFullSearch';
import {omit} from '../../../utils/immutability';

// constants

const minimalFullSearchResult = Object.values(dictFacetKeyToTitle).reduce((result, title) => {
	return {
		...result,
		[title]: {
			resultsCount: 0,
			urls: [],
		},
	};
}, {});

// reducer

const fullSearch = (state = {}, action) => {
	switch (action.type) {
		case Constants.FULL_SEARCH_REQUEST:
			return {
				...omit(state, ['error', 'results']),
				loading: true,
			};

		case Constants.FULL_SEARCH_SUCCESS: {
			const {resultsCount, urls: newUrls} = urlsAndResultsCountFromAction(action);
			const {facet} = action.payload;

			const {results: resultsFromState} = state;
			const oldUrls = urlsFromState(resultsFromState, facet);

			const mergedFacetData = {
				resultsCount,
				urls: mergeUrls(resultsCount, oldUrls, newUrls),
			};

			const results = {
				...minimalFullSearchResult,
				...resultsFromState,
				[facet]: mergedFacetData,
			};

			return {
				...omit(state, ['loading']),
				results,
			};
		}

		case Constants.FULL_SEARCH_FAILED:
			return {
				...omit(state, ['loading']),
				results: minimalFullSearchResult,
				error: action.payload.error,
			};

		case Constants.FULL_SEARCH_RESET:
			return {
				results: minimalFullSearchResult,
			};

		case Constants.INITIAL_SEARCH_RESET:
		case Constants.INITIAL_SEARCH_SUCCESS:
			// NO results field, NO minimalFullSearchResult!
			return _.isEmpty(state) ? state : {};

		default:
			return state;
	}
};

export default fullSearch;
