import {SUBMIT_ENROLLER_FAILED, SUBMIT_ENROLLER_REQUEST, SUBMIT_ENROLLER_SUCCESS} from '../constants';

export const submitEnroller = () => ({
	type: SUBMIT_ENROLLER_REQUEST
});

export const submitEnrollerSuccess = error => ({
	type: SUBMIT_ENROLLER_SUCCESS,
	payload: {
		error
	}
});

export const submitEnrollerFailed = error => ({
	type: SUBMIT_ENROLLER_FAILED,
	payload: {
		error
	}
});
