// action types

export const ADD_FILTER_CATEGORY = 'ADD_FILTER_CATEGORY';
export const REMOVE_FILTER_CATEGORY = 'REMOVE_FILTER_CATEGORY';
export const REPLACE_FILTER_CATEGORIES = 'REPLACE_FILTER_CATEGORIES';
export const REMOVE_ALL_FILTER_CATEGORIES = 'REMOVE_ALL_FILTER_CATEGORIES';
export const TOGGLE_FILTER_CATEGORIES = 'TOGGLE_FILTER_CATEGORIES';

export const INITIAL_SEARCH_REQUEST = 'INITIAL_SEARCH_REQUEST';
export const INITIAL_SEARCH_SUCCESS = 'INITIAL_SEARCH_SUCCESS';
export const INITIAL_SEARCH_FAILED = 'INITIAL_SEARCH_FAILED';
export const INITIAL_SEARCH_RESET = 'INITIAL_SEARCH_RESET';
export const INITIAL_SEARCH_REQUEST_FROM_SECOND_PAGE = 'INITIAL_SEARCH_REQUEST_FROM_SECOND_PAGE';

export const FULL_SEARCH_REQUEST = 'FULL_SEARCH_REQUEST';
export const FULL_SEARCH_SUCCESS = 'FULL_SEARCH_SUCCESS';
export const FULL_SEARCH_FAILED = 'FULL_SEARCH_FAILED';
export const FULL_SEARCH_RESET = 'FULL_SEARCH_RESET';
export const FULL_SEARCH_FOLLOWUP = 'FULL_SEARCH_FOLLOWUP';

export const GET_ALL_LIKES = 'GET_ALL_LIKES';
export const GET_LIKE = 'GET_LIKE';
export const ALL_LIKES_RECEIVED = 'ALL_LIKES_RECEIVED';
export const INCREMENT_LIKES = 'INCREMENT_LIKES';
export const POST_LIKE = 'POST_LIKE';
export const POST_UNLIKE = 'POST_UNLIKE';
export const LIKE_RECEIVED = 'LIKE_RECEIVED';
export const ARTICLE_LIKE_RECEIVED = 'ARTICLE_LIKE_RECEIVED';

export const GET_ALL_BOOKMARKS = 'GET_ALL_BOOKMARKS';
export const GET_BOOKMARK = 'GET_BOOKMARK';
export const ALL_BOOKMARKS_RECEIVED = 'ALL_BOOKMARKS_RECEIVED';
export const INCREMENT_BOOKMARKS = 'INCREMENT_BOOKMARKS';
export const POST_BOOKMARK = 'POST_BOOKMARK';
export const POST_UNBOOKMARK = 'POST_UNBOOKMARK';
export const BOOKMARK_RECEIVED = 'BOOKMARK_RECEIVED';
export const ARTICLE_BOOKMARK_RECEIVED = 'ARTICLE_BOOKMARK_RECEIVED';

export const SHOW_OVERLAY = 'SHOW_OVERLAY';
export const HIDE_OVERLAY = 'HIDE_OVERLAY';

export const SHOW_DISCLAIMER_EXTERNAL_LINK = 'SHOW_DISCLAIMER_EXTERNAL_LINK';
export const HIDE_DISCLAIMER_EXTERNAL_LINK = 'HIDE_DISCLAIMER_EXTERNAL_LINK';

export const SHOW_SURVEY = 'SHOW_SURVEY';
export const HIDE_SURVEY = 'HIDE_SURVEY';
export const SUBMIT_SURVEY_REQUEST = 'SUBMIT_SURVEY_REQUEST';
export const SUBMIT_SURVEY_SUCCESS = 'SUBMIT_SURVEY_SUCCESS';
export const SUBMIT_SURVEY_FAILED = 'SUBMIT_SURVEY_FAILED';

// test purpose
export const SET_DEFAULT = 'SET_DEFAULT';
export const SET_TEST = 'SET_TEST';

// user management
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILED = 'USER_LOGIN_FAILED';

export const USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST';
export const USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILED = 'USER_REGISTER_FAILED';

export const USER_DATA_UPDATE_REQUEST = 'USER_DATA_UPDATE_REQUEST';
export const USER_DATA_UPDATE_SUCCESS = 'USER_DATA_UPDATE_SUCCESS';
export const USER_DATA_UPDATE_FAILED = 'USER_DATA_UPDATE_FAILED';
export const USER_DATA_NOTIFY = 'USER_DATA_NOTIFY';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const USER_PASSWORD_RESET_REQUEST = 'USER_PASSWORD_RESET_REQUEST';
export const USER_PASSWORD_RESET_SUCCESS = 'USER_PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_RESET_FAILED = 'USER_PASSWORD_RESET_FAILED';

export const USER_PASSWORD_SET_NEW_REQUEST = 'USER_PASSWORD_SET_NEW_REQUEST';
export const USER_PASSWORD_FORGOT_PW_REQUEST = 'USER_PASSWORD_FORGOT_PW_REQUEST';
export const USER_PASSWORD_REQUEST_SUCCESS = 'USER_PASSWORD_REQUEST_SUCCESS';

export const USER_NLF_REQUEST = 'USER_NLF_REQUEST';
export const USER_NLF_SUCCESS = 'USER_NLF_SUCCESS';
export const USER_NLF_FAILED = 'USER_NLF_FAILED';
export const USER_NLF_UNSET = 'USER_NLF_UNSET';
export const USER_NLF_UNSET_FAILED = 'USER_NLF_UNSET_FAILED';
export const USER_NLF_UNSET_SUCCESS = 'USER_NLF_UNSET_SUCCESS';
// TODO: test check
export const SET_NLF_VAL_1 = 'SET_NLF_VAL_1';
export const SET_NLF_VAL_2 = 'SET_NLF_VAL_2';
export const SET_NLF_COMPLETE_AFTER_UPDATE = 'SET_NLF_COMPLETE_AFTER_UPDATE';

export const USER_DOUBLE_OPT_IN = 'USER_DOUBLE_OPT_IN';

export const USER_PZN_REQUEST = 'USER_PZN_REQUEST';
export const USER_PZN_SUCCESS = 'USER_PZN_SUCCESS';
export const USER_PZN_FAILED = 'USER_PZN_FAILED';
export const USER_PZN_UNSET = 'USER_PZN_UNSET';
export const USER_PZN_UNSET_FAILED = 'USER_PZN_UNSET_FAILED';
export const USER_PZN_UNSET_SUCCESS = 'USER_PZN_UNSET_SUCCESS';

export const USER_GEMEINSAM_REQUEST = 'USER_GEMEINSAM_REQUEST';
export const USER_GEMEINSAM_SUCCESS = 'USER_GEMEINSAM_SUCCESS';
export const USER_GEMEINSAM_FAILED = 'USER_GEMEINSAM_FAILED';

export const SUBMIT_CONTACT_REQUEST = 'SUBMIT_CONTACT_REQUEST';
export const SUBMIT_CONTACT_SUCCESS = 'SUBMIT_CONTACT_SUCCESS';
export const SUBMIT_CONTACT_FAILED = 'SUBMIT_CONTACT_FAILED';

export const SUBMIT_ENROLLER_REQUEST = 'SUBMIT_ENROLLER_REQUEST';
export const SUBMIT_ENROLLER_SUCCESS = 'SUBMIT_ENROLLER_SUCCESS';
export const SUBMIT_ENROLLER_FAILED = 'SUBMIT_ENROLLER_FAILED';

export const SUBMIT_CALLBACKSERVICE_REQUEST = 'SUBMIT_CALLBACKSERVICE_REQUEST';
export const SUBMIT_CALLBACKSERVICE_SUCCESS = 'SUBMIT_CALLBACKSERVICE_SUCCESS';
export const SUBMIT_CALLBACKSERVICE_FAILED = 'SUBMIT_CALLBACKSERVICE_FAILED';

// error and message notifications
export const GET_MESSAGES = 'GET_MESSAGES';
export const MESSAGES_RECEIVED = 'MESSAGES_RECEIVED';

export const NOTIFY_USER = 'NOTIFY_USER';
