export const colors = {
	// Base colors
	white: '#ffffff',
	black: '#000000',
	grey: '#3a3a3a',
	charcoal: '#747474',
	silver: '#AAA',
	steel: '#CCCCCC',
	lightgrey: '#e1e1e1',
	snow: '#F2F2F2',
	almostwhite: '#F9F9F9',
	purple: '#554F9F',

	// Primary colors
	// Prefered for generic exercices, nutrition, and coach content
	candy: 'hsl(0, 100%, 65%)', // #FF4A4A, rgb(255, 74, 74)
	carrot: 'hsl(19, 100%, 65%)', // #ff834a, rgba(255,131,74,1)
	carrot80: 'rgba(255, 131, 74, .8)', // #ff834a, rgba(255,131,74,1)
	sun: 'rgb(253, 192, 49)', // #FDC031, rgb(253, 192, 49)

	// Prefered for medical, jounal, and wellness content
	ruby: 'rgb(230, 42, 49)', // #e62a31, rgb(230, 42, 49)
	blush: 'rgb(241, 137, 145)', // rgb(241, 137, 145)

	ice: 'rgb(0, 170, 232)', // rgb(241, 137, 145)
	bubblegum: 'rgb(250, 68, 168)', // rgb(241, 137, 145)

	// Info and Focus colours to be consistent among browsers
	pictonblue: 'rgb(94, 158, 214)',

	// Service colors
	midnight: 'rgb(0, 44, 66)',
	lightblue: 'rgb(217,222,224)',
	navy: 'rgb(75, 132, 167)',
	cream: 'rgb(255, 252, 229)',

	gradient: 'linear-gradient(90deg, rgba(255,74,74,1) 0%, rgba(255,133,74,1) 100%)',
};

/**
 * Colors for alerts and other statuses.
 */
export const alertColors = {
	success: {
		dark: colors.ice,
		full: colors.ice,
		light: colors.ice,
		lighter: colors.ice,
	},
	warning: {
		dark: colors.carrot,
		full: colors.carrot,
		light: colors.carrot,
		lighter: colors.carrot,
	},
	error: {
		dark: colors.ruby,
		full: colors.ruby,
		light: colors.ruby,
		lighter: colors.ruby,
	},
	info: {
		dark: colors.bubblegum,
		full: colors.bubblegum,
		light: colors.bubblegum,
		lighter: colors.bubblegum,
	},
	focus: {
		dark: colors.carrot,
		full: colors.carrot,
		light: colors.carrot,
		lighter: colors.carrot,
	},
};

export const fonts = {
	sans_serif: '"Muli", "Arial", sans-serif',
	serif: '"Noto", "Georgia", serif',
};

export const zindex = {
	navbar: 1000,
	banner: 9999,
	streamFilterToggle: 900,
	overlayBackdrop: 9998,
	lightboxBackdrop: 9998,
	burgerMenuCross: 9998,
	dropdownListWrapper: 9998,
};

export const sizes = {
	fonts: {
		base: 22,
	},
	borderRadius: '2.5em',
	distance: {
		base: 32,
	},
	forms: {
		smallButtonHeight: '2rem',
		buttonHeight: '2.75rem',
		inputHeight: '2.75rem',
	},
	navbar: {
		default: 90,
		upstairs: 60,
		sm: {
			default: 70,
			upstairs: 50,
		},
		md: {
			default: 70,
			upstairs: 50,
		},
		lg: {
			default: 90,
			upstairs: 60,
		},
		xl: {
			default: 90,
			upstairs: 60,
		},
	},
	grid: {
		gutter: {
			xxs: 20,
		},
		breakpoints: {
			xxs: 0,
			xs: 480,
			sm: 576,
			md: 768,
			lg: 992,
			xl: 1200,
		},
		container: {
			sm: 540,
			md: 720,
			lg: 960,
			xl: 1140,
		},
	},
};
