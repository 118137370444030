import styled from 'styled-components';
import {colors, sizes} from '../../../../styles/variables';
import {gradientCandyCarrot, media, withUnit} from '../../../../styles/utils';
import {Icon} from '../../../Elements/Icon/Icon';

export const ActionBarStyled = styled.div`
	${(props) => (props.hide ? 'display: none;' : 'display: flex;')};
	justify-content: flex-end;
	align-items: center;
`;

export const FavoritesStyled = styled.div`
	margin: 0.25em;
	font-size: 0.7em;
	font-weight: 700;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	${(props) => (props.pushBottom ? `margin-bottom: ${withUnit(sizes.distance.base)};` : '')};
`;

export const ActionButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${sizes.forms.smallButtonHeight};
	height: ${sizes.forms.smallButtonHeight};
	margin: 0 0.5em;
	border-radius: 50%;

	will-change: transform, box-shadow, background-color;
	transition: all 0.3s ease-out;

	&:not([disabled]):hover {
		transform: translateY(-3px);
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	}
`;

export const ActionButtonAbs = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	//position: absolute;
	//top: 3px;
	//left: 50%;
	//transform: translate(-50%, 0);
	width: ${sizes.forms.smallButtonHeight};
	height: ${sizes.forms.smallButtonHeight};
	border-radius: 50%;
	cursor: pointer;
	will-change: transform, box-shadow, background-color;
	transition: all 0.3s ease-out;

	${media.md`
		&:not([disabled]):hover {
			// transform: translate(-50%, -3px);
			transform: translate(0, -3px);
			box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
		}
	`};
`;

export const ActionFavoriteStyled = styled(ActionButtonAbs)`
	position: relative;
	${(props) => (props.liked ? `` : `background: ${colors.lightgrey};`)};
	z-index: 1;

	&:after {
		z-index: -1;
		content: '';
		position: absolute;
		display: block;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		${gradientCandyCarrot()};
		will-change: opacity;
		border-radius: 50%;
		${(props) => (props.liked ? `opacity: 1;` : `opacity: 0;`)};
		transition: opacity 0.15s ease-in;
	}
`;

export const ActionBookmarkStyled = styled(ActionButton)`
	background-color: ${colors.steel};
`;

export const IconStyled = styled(Icon)`
	svg {
		fill: ${colors.white};
		width: 1em;
		height: 1em;
	}
`;

export const FlexBlockTest = styled.div`
	display: flex;
`;