import _ from 'lodash';
import {put, select, takeEvery, takeLatest} from 'redux-saga/effects';

import {fullSearchFailed, fullSearchFollowUp, fullSearchSuccess, resetFullSearch} from '../state/actions/search';
import {FULL_SEARCH_FOLLOWUP, FULL_SEARCH_REQUEST} from '../state/constants';
import {getFullSearchData} from '../utils/fetchAPI';

// helper for select()

export const getHttpQueries = (state) => state.initialSearch.httpQueries;

// local helper

const outstandingHttpQueries = (results) => {
	const alreadyDoneHttpQuery = results.http_query;
	return _.get(results, 'pagination.pages', [])
		.map((entry) => entry.query)
		.filter((query) => query !== alreadyDoneHttpQuery);
};

// helper generators for full search (also used in initialSearch.js)

export function* fetchFullSearchResultsWithHttpQueries(httpQueries) {
	yield put(resetFullSearch()); // always reset, not only when httQueries is empty
	for (const [title, httpQuery] of Object.entries(httpQueries)) {
		const facet = title;
		try {
			const results = yield getFullSearchData(httpQuery);
			yield put(fullSearchSuccess(facet, results));
			yield put(fullSearchFollowUp(facet, outstandingHttpQueries(results)));
		} catch (error) {
			yield put(fullSearchFailed(error));
			// add additional error handling, if necessary
		}
	}
}

// full search

function* fetchFullSearchResults() {
	const httpQueries = yield select(getHttpQueries);
	yield* fetchFullSearchResultsWithHttpQueries(httpQueries);
}

function* fetchFullSearchFollowupResults(action) {
	const {facet, outstandingHttpQueries} = action.payload;
	for (let httpQuery of outstandingHttpQueries) {
		const results = yield getFullSearchData(httpQuery);
		yield put(fullSearchSuccess(facet, results));
	}
}

export default function* watchFullSearch() {
	yield takeLatest(FULL_SEARCH_REQUEST, fetchFullSearchResults);
	yield takeEvery(FULL_SEARCH_FOLLOWUP, fetchFullSearchFollowupResults);
}
