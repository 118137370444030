import {put, takeLatest} from 'redux-saga/effects';

import {fetchFullSearchResultsWithHttpQueries} from './fullSearch';

import {INITIAL_SEARCH_REQUEST, INITIAL_SEARCH_REQUEST_FROM_SECOND_PAGE} from '../state/constants';
import {initialSearchFailed, initialSearchSuccess} from '../state/actions/search';
import {convertInitialSearchResults} from '../state/reducers/search/convertingForInitialSearch';
import {getInitialSearchData} from '../utils/fetchAPI';

function* fetchInitialSearchResults(action) {
	const {searchTerm} = action.payload;
	try {
		const results = yield getInitialSearchData(searchTerm);
		yield put(initialSearchSuccess(results));
	} catch (error) {
		yield put(initialSearchFailed(error));
	}
}

function* fetchInitialSearchResultsAndContinueToSecondPage(action) {
	// do not bother with first search-result page, instead show second search-result page
	const {searchTerm} = action.payload;
	try {
		// CHANGED: structure of resultset is different from initialSaga - use of res.initialValues - this failed before
		const res = yield getInitialSearchData(searchTerm);
		const results = res.initialResults;
		const {httpQueries} = convertInitialSearchResults(results, false);
		yield* fetchFullSearchResultsWithHttpQueries(httpQueries);
	} catch (error) {
		yield put(initialSearchFailed(error));
	}
}

export default function* watchInitialSearch() {
	yield takeLatest(INITIAL_SEARCH_REQUEST, fetchInitialSearchResults);
	yield takeLatest(INITIAL_SEARCH_REQUEST_FROM_SECOND_PAGE, fetchInitialSearchResultsAndContinueToSecondPage);
}
