import * as Constants from '../../constants';
import {convertInitialSearchResults} from './convertingForInitialSearch';
import {omit} from '../../../utils/immutability';

const initialSearch = (state = {}, action) => {
	switch (action.type) {
		case Constants.INITIAL_SEARCH_REQUEST:
			return {
				...omit(state, ['error', 'httpQueries', 'results']),
				loading: true,
				searchTerm: action.payload.searchTerm || '' // prohibit 'undefined' or 'null' as value for searchTerm
			};

		case Constants.INITIAL_SEARCH_SUCCESS: {
			const {results, httpQueries} = convertInitialSearchResults(action.payload.results.initialResults);
			const {count} = action.payload.results;
			return {
				...omit(state, ['loading']), // remove loading instead of setting to false
				results,
				count,
				httpQueries
			};
		}

		case Constants.INITIAL_SEARCH_FAILED:
			return {
				...omit(state, ['httpQueries', 'loading', 'results']),
				error: action.payload.error
			};

		case Constants.INITIAL_SEARCH_RESET:
			return {
				searchTerm: '' // prohibit 'undefined' or 'null' as value for searchTerm
			};

		default:
			return state;
	}
};

export default initialSearch;
