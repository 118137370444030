import {all} from 'redux-saga/effects';
import watchInitialSearch from './initialSearch';
import watchFullSearch from './fullSearch';
import {
	watchUserDataUpdate,
	watchUserLogin,
	watchUserLogout,
	watchUserRegister,
	watchUserNLFUpdate,
	watchUserNLFUnset,
	watchUserPZNUpdate,
	watchUserPZNUnset,
	watchUserGemeinsam,
	watchUserPasswordReset,
	watchUserPasswordSetNew,
	watchUserDoubleOptIn,
	watchUserForgotPW,
} from './userSagas';
import {watchGetAllLikes, watchPostLike, watchGetLikeById, watchPostUnlike} from './likeSagas';
import {watchGetAllBookmarks, watchPostBookmark, watchPostUnbookmark} from './bookmarkSagas';
import {watchSubmitRequests} from './genericSubmitSagas';

export default function* rootSaga() {
	yield all([
		watchInitialSearch(),
		watchFullSearch(),
		watchGetAllLikes(),
		watchPostLike(),
		watchPostUnlike(),
		watchGetLikeById(),
		watchGetAllBookmarks(),
		watchPostBookmark(),
		watchPostUnbookmark(),
		watchUserDataUpdate(),
		watchUserPasswordReset(),
		watchUserLogin(),
		watchUserLogout(),
		watchUserRegister(),
		watchUserNLFUpdate(),
		watchUserNLFUnset(),
		watchUserPZNUpdate(),
		watchUserPZNUnset(),
		watchUserGemeinsam(),
		watchSubmitRequests(),
		watchUserDoubleOptIn(),
		watchUserPasswordSetNew(),
		watchUserForgotPW(),
	]);
}
