import * as Constants from '../../constants';

const defaultReducer = (state = 'default', action) => {
	switch (action.type) {
		case Constants.SET_DEFAULT:
			return state;
		default:
			return state;
	}
};

export default defaultReducer;
