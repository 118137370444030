import {put, takeLatest} from 'redux-saga/effects';
import {
	GET_ALL_BOOKMARKS,
	ALL_BOOKMARKS_RECEIVED,
	POST_BOOKMARK,
	BOOKMARK_RECEIVED,
	POST_UNBOOKMARK
} from '../state/constants';
import {getAllBookmarks, postBookmark, postUnbookmark} from '../utils/fetchAPI';

function* fetchAllBookmarks(param) {
	const results = yield getAllBookmarks(param);
	yield put({
		type: ALL_BOOKMARKS_RECEIVED,
		results
	});
}

function* postBookmarkSaga(param) {
	const {userId, postId} = param.payload;

	const res = yield postBookmark(userId, postId);
	const result = {
		value: 'add',
		statusCode: res.code,
		postId
	};
	yield put({
		type: BOOKMARK_RECEIVED,
		result
	});
}

function* postUnbookmarkSaga(param) {
	const {userId, postId} = param.payload;

	const res = yield postUnbookmark(parseInt(userId, 10), postId);
	const result = {
		value: 'remove',
		statusCode: res.code,
		postId
	};
	yield put({
		type: BOOKMARK_RECEIVED,
		result
	});
}

export function* watchGetAllBookmarks() {
	yield takeLatest(GET_ALL_BOOKMARKS, fetchAllBookmarks);
}

export function* watchPostBookmark() {
	yield takeLatest(POST_BOOKMARK, postBookmarkSaga);
}

export function* watchPostUnbookmark() {
	yield takeLatest(POST_UNBOOKMARK, postUnbookmarkSaga);
}
