import * as Constants from '../../constants';
import {omit} from '../../../utils/immutability';

const initialState = {
	nlf: 0,
	nlfObject: {},
};

const user = (state = initialState, action) => {
	switch (action.type) {
		case Constants.USER_LOGIN_REQUEST:
			return {...state, loading: true};

		case Constants.USER_LOGIN_SUCCESS: {
			const {user} = action.payload;
			return {
				...omit(state, ['error', 'loading']),
				user,
				nlfObject: {},
			};
		}
		case Constants.USER_LOGIN_FAILED: {
			return {
				...omit(state, ['loading']),
			};
		}
		case Constants.USER_LOGOUT_REQUEST:
			return {...state, loading: true};
		case Constants.USER_LOGOUT_SUCCESS:
			return omit(state, ['user', 'loading']);
		case Constants.USER_LOGOUT_FAILED: {
			return {
				...omit(state, ['loading']),
			};
		}
		case Constants.USER_REGISTER_REQUEST:
			// we do NOT store the user here (or in _SUCCESS), because that would mean it's already the current user
			return {...state, loading: true};
		case Constants.USER_REGISTER_SUCCESS:
			return omit(state, ['user', 'loading']);
		case Constants.USER_REGISTER_FAILED: {
			return {...omit(state, ['loading'])};
		}
		case Constants.CLEAR_ERRORS:
			return omit(state, ['loading']);

		case Constants.USER_DATA_UPDATE_REQUEST:
			return {
				...state,
				loading: true,
			};
		case Constants.USER_DATA_UPDATE_SUCCESS: {
			const {user} = action.payload;
			return {
				...omit(state, ['loading']),
				user,
			};
		}
		case Constants.USER_DATA_UPDATE_FAILED: {
			return {
				...omit(state, ['loading']),
			};
		}

		case Constants.USER_NLF_REQUEST: {
			const {nlf} = action.payload;
			if (state.user) {
				return {
					...state,
					nlfObject: {},
					user: {
						...state.user,
						nlf: nlf,
					},
					nlf,
				};
			}

			return {
				...state,
				nlf,
			};
		}

		case Constants.SET_NLF_VAL_1: {
			return {
				...state,
				nlfObject: {
					...state.nlfObject,
					nlfVal1: action.payload.nlfVal1,
				},
			};
		}

		case Constants.SET_NLF_VAL_2: {
			return {
				...state,
				nlfObject: {
					...state.nlfObject,
					nlfVal2: action.payload.nlfVal2,
				},
			};
		}

		case Constants.USER_NLF_SUCCESS: {
			return {
				...state,
			};
		}

		case Constants.USER_NLF_UNSET: {
			return {...state};
		}

		case Constants.USER_NLF_UNSET_FAILED: {
			return {
				...omit(state, ['loading']),
			};
		}

		case Constants.USER_NLF_UNSET_SUCCESS: {
			return {
				...omit(state, ['nlf', 'loading']),
			};
		}

		case Constants.USER_PZN_REQUEST: {
			const {pzn} = action.payload;
			return {
				...state,
				pzn,
			};
		}

		case Constants.USER_PZN_SUCCESS: {
			return state;
		}

		case Constants.USER_PZN_FAILED: {
			return state;
		}

		case Constants.USER_GEMEINSAM_REQUEST: {
			const {gemeinsam} = action.payload;
			return {
				...state,
				gemeinsam,
			};
		}

		case Constants.USER_GEMEINSAM_SUCCESS: {
			return state;
		}

		case Constants.USER_GEMEINSAM_FAILED: {
			return state;
		}

		case Constants.USER_PASSWORD_RESET_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}

		case Constants.USER_PASSWORD_RESET_SUCCESS: {
			return {
				...omit(state, ['payload', 'loading']),
			};
		}

		case Constants.USER_PASSWORD_RESET_FAILED: {
			return state;
		}

		case Constants.USER_PASSWORD_SET_NEW_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}

		case Constants.USER_PASSWORD_FORGOT_PW_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}

		case Constants.USER_PZN_UNSET: {
			return {...state};
		}

		case Constants.USER_PZN_UNSET_FAILED: {
			return {
				...omit(state, ['loading']),
			};
		}

		case Constants.USER_PZN_UNSET_SUCCESS: {
			return {
				...omit(state, ['pzn', 'loading']),
			};
		}

		case Constants.TOGGLE_FILTER_CATEGORIES:
			const {showPersonal} = action.payload;
			return {
				...state,
				showPersonal,
			};

		default:
			return state;
	}
};

export default user;
