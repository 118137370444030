import _ from 'lodash';
import {ModifyEntriesAndReassembleToObject, omit} from '../../../utils/immutability';

const lookupTitleFromKey = {
	// contains sort order
	top: 'Top-Treffer',
	multiplesklerose: 'Multiple Sklerose',
	lebenmitms: 'Leben mit MS',
	service: 'Service'
};

// helper functions
// todo add unit tests

const sortTitles = data =>
	_.reduce(
		lookupTitleFromKey,
		(result, title, key) => ({
			...result,
			[key]: data[key]
		}),
		{}
	);

const replaceTitles = data =>
	new ModifyEntriesAndReassembleToObject(data).map((key, entry) => [lookupTitleFromKey[key] || key, entry]).result();

const filterOutEmptyFacets = data =>
	new ModifyEntriesAndReassembleToObject(data)
		.filter((key, entry) => entry.urls.length) // filter out/remove entries without urls
		.result();

const createHttpQueries = data =>
	new ModifyEntriesAndReassembleToObject(data || {})
		.map((key, entry) => [key, entry.http_query])
		.filter((key, str) => str && !['top', lookupTitleFromKey.top].includes(key)) // no empty/nil values, no 'top'/'Top-Treffer'
		.result();

const removeHttpQueryEntries = data =>
	new ModifyEntriesAndReassembleToObject(data)
		.map((title, entry) => {
			const newEntry = omit(entry, 'http_query');
			return [title, newEntry];
		})
		.result();

const removeTopHitFromOtherFacets = data => {
	const topHitTitle = lookupTitleFromKey.top;
	const topHitEntryUrl = _.get(data, [topHitTitle, 'urls', 0, 'url']);

	return new ModifyEntriesAndReassembleToObject(data)
		.map((title, item) => {
			if (title === topHitTitle) {
				return [title, item]; // leave unchanged
			}
			// return urls without topHitEntryUrl
			const entries = (item.urls || []).filter(entry => entry.url !== topHitEntryUrl);
			return [
				title,
				{
					...item,
					urls: entries
				}
			];
		})
		.result();
};

// exported functions

export const convertInitialSearchResults = (data, doRemoveTopHitsFromOtherFacets = true) => {
	if (_.isNil(data)) {
		// no search results because of empty search string
		const results = null;
		const httpQueries = {};
		return {httpQueries, results};
	}
	let results = sortTitles(data); // might already be sorted, but we want to be sure
	results = replaceTitles(results);
	if (doRemoveTopHitsFromOtherFacets) {
		results = removeTopHitFromOtherFacets(results);
	}
	results = filterOutEmptyFacets(results);
	const httpQueries = createHttpQueries(results);
	results = removeHttpQueryEntries(results); // http_entry field is not wanted in the store
	return {httpQueries, results};
};
