import * as Constants from '../constants';

export const userLogin = (credentials) => ({
	type: Constants.USER_LOGIN_REQUEST,
	payload: {
		credentials,
	},
});

export const userLoginSuccess = (user) => ({
	type: Constants.USER_LOGIN_SUCCESS,
	payload: {
		user,
	},
});

export const userLoginFailed = (error) => ({
	type: Constants.USER_LOGIN_FAILED,
	payload: {
		error,
	},
});

export const userLogout = () => ({
	type: Constants.USER_LOGOUT_REQUEST,
});

export const userLogoutSuccess = (response) => ({
	type: Constants.USER_LOGOUT_SUCCESS,
	payload: {
		response,
	},
});

export const userLogoutFailed = (error) => ({
	type: Constants.USER_LOGOUT_FAILED,
	payload: {
		error,
	},
});

export const userRegister = (payload) => ({
	type: Constants.USER_REGISTER_REQUEST,
	payload: {
		payload,
	},
});

export const userRegisterSuccess = (response) => ({
	type: Constants.USER_REGISTER_SUCCESS,
	payload: {
		response,
	},
});
// TODO: new nlf handler
export const setNLFVal1 = (nlfVal1) => ({
	type: Constants.SET_NLF_VAL_1,
	payload: {
		nlfVal1,
	},
});

export const setNLFVal2 = (nlfVal2) => ({
	type: Constants.SET_NLF_VAL_2,
	payload: {
		nlfVal2,
	},
});

export const setNLF = (nlf) => ({
	type: Constants.USER_NLF_REQUEST,
	payload: {
		nlf,
	},
});

export const setNLFSuccess = (error) => ({
	type: Constants.USER_NLF_SUCCESS,
	payload: {
		error,
	},
});

export const setNLFFailed = () => ({
	type: Constants.USER_NLF_FAILED,
});

export const unsetNLF = () => ({
	type: Constants.USER_NLF_UNSET,
});

export const unsetNLFFailed = () => ({
	type: Constants.USER_NLF_UNSET_FAILED,
});

export const unsetNLFSuccess = () => ({
	type: Constants.USER_NLF_UNSET_SUCCESS,
});

export const setPZN = (pzn) => ({
	type: Constants.USER_PZN_REQUEST,
	payload: {
		pzn,
	},
});

export const setPZNSuccess = () => ({
	type: Constants.USER_PZN_SUCCESS,
});

export const setPZNFailed = () => ({
	type: Constants.USER_PZN_FAILED,
});

export const unsetPZN = () => ({
	type: Constants.USER_PZN_UNSET,
});

export const unsetPZNFailed = () => ({
	type: Constants.USER_PZN_UNSET_FAILED,
});

export const unsetPZNSuccess = () => ({
	type: Constants.USER_PZN_UNSET_SUCCESS,
});

export const userRegisterFailed = (error) => ({
	type: Constants.USER_REGISTER_FAILED,
	payload: {
		error,
	},
});

export const setGemeinsam = (gemeinsam) => ({
	type: Constants.USER_GEMEINSAM_REQUEST,
	payload: {
		gemeinsam,
	},
});

export const setGemeinsamSuccess = () => ({
	type: Constants.USER_GEMEINSAM_SUCCESS,
});

export const setGemeinsamFailed = () => ({
	type: Constants.USER_GEMEINSAM_FAILED,
});

export const doubleOptIn = (credentials) => ({
	type: Constants.USER_DOUBLE_OPT_IN,
	payload: {
		credentials,
	},
});

export const resetPassword = (credentials) => ({
	type: Constants.USER_PASSWORD_RESET_REQUEST,
	payload: {
		credentials,
	},
});

export const forgotPassword = (credentials) => ({
	type: Constants.USER_PASSWORD_FORGOT_PW_REQUEST,
	payload: {
		credentials,
	},
});

export const setNewPassword = (credentials) => ({
	type: Constants.USER_PASSWORD_SET_NEW_REQUEST,
	payload: {
		credentials,
	},
});

export const resetPasswordSuccess = (info) => ({
	type: Constants.USER_PASSWORD_RESET_SUCCESS,
	payload: {
		info,
	},
});

export const resetPasswordFailed = (error) => ({
	type: Constants.USER_PASSWORD_RESET_FAILED,
	payload: {
		error,
	},
});

export const requestPasswordSuccess = (info) => ({
	type: Constants.USER_PASSWORD_REQUEST_SUCCESS,
	payload: {
		info,
	},
});

export const userDataUpdate = (data) => ({
	type: Constants.USER_DATA_UPDATE_REQUEST,
	payload: {
		data,
	},
});

export const userDataUpdateSuccess = (info, userData) => ({
	type: Constants.USER_DATA_UPDATE_SUCCESS,
	payload: {
		info,
		user: userData,
	},
});

export const userDataUpdateFailed = (error) => ({
	type: Constants.USER_DATA_UPDATE_FAILED,
	payload: {
		error,
	},
});

export const userDataNotify = (message, showButton = true) => ({
	type: Constants.USER_DATA_NOTIFY,
	payload: {
		message,
		showButton,
	},
});

export const clearErrors = () => ({
	type: Constants.CLEAR_ERRORS,
});
