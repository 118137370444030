import {SUBMIT_CONTACT_FAILED, SUBMIT_CONTACT_REQUEST, SUBMIT_CONTACT_SUCCESS} from '../../constants/index';
import {omit} from '../../../utils/immutability';

export default (state = {}, action) => {
	switch (action.type) {
		case SUBMIT_CONTACT_REQUEST:
			return {
				...state,
				loading: true
			};

		case SUBMIT_CONTACT_SUCCESS: {
			// const {response} = action.payload;  // not used here
			return {
				...omit(state, ['loading'])
			};
		}

		case SUBMIT_CONTACT_FAILED: {
			// const {error} = action.payload;  // not used here
			return {
				...omit(state, ['loading'])
			};
		}

		default:
			return state;
	}
};
