import {SUBMIT_CONTACT_FAILED, SUBMIT_CONTACT_REQUEST, SUBMIT_CONTACT_SUCCESS} from '../constants';

export const submitContact = (data) => ({
	type: SUBMIT_CONTACT_REQUEST,
	payload: {
		data,
	},
});

export const submitContactSuccess = (info) => ({
	type: SUBMIT_CONTACT_SUCCESS,
	payload: {
		info,
	},
});

export const submitContactFailed = (error) => ({
	type: SUBMIT_CONTACT_FAILED,
	payload: {
		error,
	},
});
