import {css} from 'styled-components';
import {colors} from './variables';

/**
 * Mixin: aspect ratio
 *
 * @param width
 * @param height
 * @returns {string}
 */
export function aspectRatio(width, height) {
	const padding = (height / width) * 100;

	return css`
		position: relative;

		&::before {
			display: block;
			content: '';
			width: 100%;
			padding-top: ${padding}%;
		}
	`;
}

/**
 * Mixin: button gradient
 *
 * @returns {string}
 */
export function gradientButton() {
	return css`
		background-color: rgba(255, 74, 74, 1);
		background-image: linear-gradient(90deg, rgba(255, 74, 74, 1) 0%, rgba(255, 131, 74, 1) 100%);
		background-size: 150% 100%;
		background-position: left top;
		transition: all 0.3s ease-out;

		&:hover {
			background-position: right top;
		}
	`;
}

/**
 * Mixin: gradient 1
 *
 * @returns {string}
 */
export function gradientCandyCarrot() {
	return css`
		background: rgba(255, 74, 74, 1);
		background: linear-gradient(90deg, rgba(255, 74, 74, 1) 0%, rgba(255, 131, 74, 1) 100%);
	`;
}
export function gradientCandyCarrotRevert() {
	return css`
		background: rgba(255, 131, 74, 1);
		background: linear-gradient(90deg, rgba(255, 131, 74, 1) 0%, rgba(255, 74, 74, 1) 100%);
	`;
}

export function gradientService() {
	return css`
		background: rgb(75, 132, 167);
		background: linear-gradient(90deg, rgb(117, 164, 193) 0%, rgb(75, 132, 167) 100%);
	`;
}

/**
 * Mixin: gradient 2
 *
 * @returns {string}
 */
export function gradientCandyCarrotSun() {
	return css`
		background: rgba(255, 74, 74, 1);
		background: linear-gradient(
			90deg,
			rgba(255, 74, 74, 1) 0%,
			rgba(255, 131, 74, 1) 50%,
			rgba(253, 192, 49, 1) 100%
		);
	`;
}
export function gradientCandyCarrotSunRevert() {
	return css`
		background: rgba(253, 192, 49, 1);
		background: linear-gradient(
			90deg,
			rgba(253, 192, 49, 1) 0%,
			rgba(255, 131, 74, 1) 50%,
			rgba(255, 74, 74, 1) 100%
		);
	`;
}

export function gradientGrey() {
	return css`
		background: rgba(0, 0, 0, 0.1);
		background: linear-gradient(90deg, rgba(189, 195, 199, 1) 0%, rgba(44, 62, 80, 1) 100%);
	`;
}

export function gradientLightgrey() {
	return css`
		background: rgba(0, 0, 0, 0.1);
		background: linear-gradient(90deg, rgba(225, 225, 225, 1) 0%, rgba(242, 242, 242, 1) 100%);
	`;
}

/**
 * Mixin: orange border.
 *
 * Returns orange border css.
 *
 * @returns {string}
 */
const borderPositionCenterCss = css`
	left: 50%;
	transform: translate(-50%, 0);
`;

const borderPositionLeftCss = css`
	left: 0;
`;

const borderPositionRightCss = css`
	right: 0;
`;

export function orangeBorder(position) {
	return css`
		&::after {
			position: absolute;
			bottom: 0;
			width: 4em;
			height: 6px;
			background-color: ${colors.carrot};
			content: '';
			${position === 'center'
				? borderPositionCenterCss
				: position === 'right'
				? borderPositionRightCss
				: borderPositionLeftCss};
		}
	`;
}
/**
 * Mixin: image gradient.
 *
 * Returns gradient css.
 *
 * @param size unit string, defaults to half
 * @param direction unit string, defaults to 180deg
 * @returns {string}
 */
export function imageGradient(size = 'half', direction = '180deg') {
	return css`
		&::after {
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			${size === 'half'
				? `
				width: ${direction === '180deg' ? `100%` : `50%`};
				height: ${direction === '180deg' ? `50%` : `100%`};
			`
				: `
				width: 100%;
				height: 100%;
			`};

			content: '';
			background: rgba(0, 0, 0, 0.1);
			background: linear-gradient(${direction}, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
		}
	`;
}

/**
 * Mixin: text gradient.
 *
 * Returns gradient css.
 *
 * @returns {string}
 */
export function textGradient() {
	return css`
		&::after {
			display: block;
			position: absolute;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 15%;

			content: '';
			background: rgba(255, 255, 255, 0);
			background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%);
		}
	`;
}

/**
 * Mixin: with unit.
 *
 * Returns value with unit.
 *
 * @param value
 * @param unit unit string, defaults to px
 * @returns {string}
 */
export function withUnit(value, unit = 'px') {
	return value + unit;
}

/**
 * Media queries.
 */

export const mediaTypes = {
	xxs: 'screen and (min-width: 200px)',
	xs: 'screen and (min-width: 480px)',
	smMax: 'screen and (max-width: 576px)',
	sm: 'screen and (min-width: 576px)',
	mdMax: 'screen and (max-width: 768px)',
	md: 'screen and (min-width: 768px)',
	lg: 'screen and (min-width: 992px)',
	lgMax: 'screen and (max-width: 992px)',
	xl: 'screen and (min-width: 1200px)',
	xxl: 'screen and (min-width: 1440px)',
	xxxl: 'screen and (min-width: 2000px)',
	print: 'print',
};

export const matchMedia = () =>
	Object.entries(mediaTypes).reduce((matches, [mediaType, mediaQuery]) => {
		if (typeof window !== 'undefined') {
			if (window.matchMedia(mediaQuery).matches) {
				return {
					...matches,
					[mediaType]: mediaQuery,
				};
			}
		}
		return matches;
	}, {});

export const media = Object.keys(mediaTypes).reduce((media, type) => {
	// eslint-disable-next-line no-param-reassign
	media[type] = (...args) => css`
		@media ${mediaTypes[type]} {
			${css(...args)};
		}
	`;
	return media;
}, {});
