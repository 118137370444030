import {HIDE_OVERLAY, SHOW_OVERLAY} from '../../constants';
import {omit} from '../../../utils/immutability';
import {KEY_NAME} from './constants';

export default (state = {}, action) => {
	switch (action.type) {
		case SHOW_OVERLAY: {
			const {name} = action.payload;
			return {
				...state,
				[KEY_NAME]: name
			};
		}
		case HIDE_OVERLAY:
			return omit(state, [KEY_NAME]);
		default:
			return state;
	}
};
