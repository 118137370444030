export const userNormalize = (responseData) => ({
	UserName: responseData.userName,
	FirstName: responseData.firstName,
	LastName: responseData.lastName,
	UserId: responseData.UserId,
	id: responseData.id,
	Agreement: responseData.isPrivacyPolicyAccepted,
	Email: responseData.email,
	Title: responseData.title,
	Salutation: responseData.salutation,
	Newsletter: responseData.metadata.newsletter,
	MediaId: responseData.metadata.media_id,
	MediaOthers: responseData.metadata.media_others,
	PatientProgramStatus: responseData.programs,
	pspToken: responseData.pspToken,
	isActivated: responseData.isActivated,
	nlf: responseData.nlf,
	programs: responseData.programs,
	permissions: responseData.permissions,
});
