export const postLoginUser = async (credentials) => {
	// const encodedUsername = encodeURIComponent(credentials.username);
	// const encodedPassword = encodeURIComponent(credentials.password);

	const formData = new URLSearchParams({
		username: credentials.username,
		password: credentials.password,
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const optionsForum = {
		method: 'POST',
		mode: 'no-cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_LOGIN_ENDPOINT}`, options);
	const response = await res.json();
	const resParsed = JSON.parse(response);

	if (resParsed.UserId) {
		fetch(`${process.env.GATSBY_FORUM_LOGIN}/${process.env.GATSBY_FORUM_LOGIN_ENDPOINT}`, optionsForum);
	}

	if (resParsed.UserId) {
		const userExtra2 = fetch(`${process.env.GATSBY_UMS_URL}/userdata/${resParsed.UserId}`, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		})
			.then((resultExtra) => resultExtra.json())
			.then((resExtra) => {
				const newresponse = {
					...resParsed,
					nlf: resExtra.value.nlf,
				};
				return JSON.stringify(newresponse);
			});

		return userExtra2.then((userExtra2Response) => {
			return userExtra2Response;
		});
	}

	return response;
};

export const getLogoutUser = async () => {
	const options = {
		method: 'GET',
		mode: 'no-cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
	};

	const res = await fetch(`${process.env.GATSBY_FORUM_LOGIN}/${process.env.GATSBY_UMS_LOGOUT_ENDPOINT}`, options);
	const response = await res.json();
	return response;
};

export const postRegisterUser = async (data) => {
	const reg = data.payload.payload;
	const {
		UserName,
		Email,
		Salutation,
		FirstName,
		LastName,
		Password,
		PasswordConfirm,
		Newsletter,
		Agreement,
		MediaId,
		MediaOthers,
	} = reg;

	const formData = new URLSearchParams({
		username: UserName,
		email: Email,
		salutation: Salutation,
		firstname: FirstName,
		lastname: LastName,
		password: Password,
		password2: PasswordConfirm,
		newsletter: Newsletter ? 'True' : 'False',
		agreement: Agreement ? 'True' : 'False',
		mediaId: MediaId,
		mediaOthers: MediaOthers || '',
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_REGISTER_ENDPOINT}`, options);
	const response = await res.json();
	return response;
};

export const postResetPassword = async (data) => {
	const {UserName, Password, Password1, Password2, Email, UserId, FirstName, LastName} = data;
	const formData = new URLSearchParams({
		username: UserName,
		password: Password,
		password1: Password1,
		password2: Password2,
		email: Email,
		userId: UserId,
		firstname: FirstName,
		lastname: LastName,
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_REGISTER_ENDPOINT}`, options);
	const response = await res.json();

	return response;
};

export const postSetNewPassword = async (data) => {
	const {userId, code, password} = data;
	const formData = new URLSearchParams({
		userId,
		code,
		password,
		passwordConfirmation: password,
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_SET_NEW_PW_ENDPOINT}`, options);
	const response = await res.json();

	return response;
};

export const postUserData = async (data) => {
	console.log('data: ', data);
	const {
		UserName,
		Email,
		Salutation,
		FirstName,
		LastName,
		Password,
		PasswordConfirm,
		Newsletter,
		Agreement,
		MediaId,
		MediaOthers,
		id,
	} = data;

	const formData = new URLSearchParams({
		id,
		username: UserName,
		email: Email,
		salutation: Salutation,
		firstname: FirstName,
		lastname: LastName,
		password: Password || '',
		password2: PasswordConfirm || '',
		newsletter: Newsletter ? 'True' : 'False',
		agreement: Agreement ? 'True' : 'False',
		mediaId: MediaId,
		mediaOthers: MediaOthers || '',
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_REGISTER_ENDPOINT}`, options);
	const response = await res.json();

	const updated = {...data, response};

	return updated;
};

export const postDOI = async (data) => {
	const {userId, code} = data;
	const formData = new URLSearchParams({
		userId: encodeURIComponent(userId),
		code: encodeURIComponent(code),
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_DOI}`, options);
	if (res.status !== 200) {
		return res;
	}
	const response = await res.json();

	return response;
};

export const postForgotPassword = async (data) => {
	const {UserName, Email} = data;

	const formData = new URLSearchParams({
		username: UserName,
		email: Email,
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};

	const res = await fetch(`${process.env.GATSBY_UMS_URL}/${process.env.GATSBY_UMS_FORGOT_PW_ENDPOINT}`, options);
	const response = await res.json();

	return response;
};

export const getUserData = async (userId) => {
	const options = {
		method: 'GET',
		mode: 'cors',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	};
	const res = await fetch(`${process.env.GATSBY_UMS_URL}/userdata/${userId}`, options);
	const response = await res.json();
	return response;
};

export const postNLF = async (data) => {
	const finalNlf = data.nlfVal1.value + data.nlfVal2.value + 10;
	const options = {
		method: 'POST',
		mode: 'cors',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			user_id: parseInt(data.UserId, 10),
			nlf: finalNlf,
		}),
	};
	await fetch(`${process.env.GATSBY_UMS_URL}/userdata`, options);
	const userData = await getUserData(data.UserId);
	return userData;
};

export const postTokenUser = async (credentials) => {
	const formData = new URLSearchParams({
		username: credentials.username,
		password: credentials.password,
	});

	const options = {
		method: 'POST',
		mode: 'cors',
		redirect: 'follow',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		body: formData,
	};
	const res = await fetch(`${process.env.GATSBY_PSP_API_URL}/api/account/v1/login`, options);
	const response = await res.json();
	localStorage.setItem('userToken', JSON.stringify(response.token));
	return response;
};
