import {
	postLoginUser,
	postUserData,
	postRegisterUser,
	postResetPassword,
	postSetNewPassword,
	postForgotPassword,
	getLogoutUser,
	postDOI,
	postNLF,
	postTokenUser,
} from './_userAPI';
import {postUnbookmark, postBookmark, getAllBookmarks} from './_bookmarkAPI';
import {getAllLikes, getLikeById, postLike, postUnlike} from './_likesAPI';

export {
	postLoginUser,
	postUserData,
	postRegisterUser,
	postResetPassword,
	postSetNewPassword,
	postForgotPassword,
	getLogoutUser,
	postBookmark,
	postUnbookmark,
	getAllBookmarks,
	getAllLikes,
	getLikeById,
	postLike,
	postUnlike,
	postDOI,
	postNLF,
	postTokenUser,
};

const checkStatus = (response) => {
	if (response.status >= 200 && response.status < 300) {
		return response;
	}
	const error = new Error(`HTTP Error ${response.statusText}`);
	error.status = response.statusText;
	error.response = response;
	throw error;
};

const parseJson = (response) => {
	// re-raise an new error, based on a string (which is expected in <ErrorPanel>)
	const errorHandler = (e) => {
		throw `${e.message}", trat auf während des Parsens des Suchergebnisses.`;
	};
	return response.json().catch(errorHandler);
};

export const getInitialSearchData = async (searchTerm) => {
	if (!searchTerm.trim()) {
		// if searchTerm is empty or consists only of whitespace,
		// we don't even start the search at all, instead we pass null
		return Promise.resolve(null);
	}
	const options = {mode: 'cors'};
	const initialResults = await fetch(
		`${process.env.GATSBY_SOLR_API_URL}/${process.env.GATSBY_SOLR_API_SEARCH_CATEGORY_DIR}/?q=${searchTerm}`,
		options
	)
		.then(checkStatus)
		.then(parseJson);

	const fullResults = await fetch(
		`${process.env.GATSBY_SOLR_API_URL}/${process.env.GATSBY_SOLR_API_SEARCH_FULL_DIR}/?q=${searchTerm}`,
		options
	)
		.then(checkStatus)
		.then(parseJson);
	const count = fullResults;
	const returnValue = {initialResults, count: count.results_count};

	return returnValue;
};

export const getFullSearchData = (httpQuery) => {
	// httpQuery is like '?q=blasenst%C3%B6rung&language=de&start=0&facetq=categories_facet:multiple-sklerose'
	if (!httpQuery) {
		// if empty, we don't even start the search at all,
		// instead we pass null
		return Promise.resolve(null);
	}
	const searchParams = new URLSearchParams(httpQuery);
	const firstParam = searchParams.get(searchParams.keys().next().value);
	const options = {mode: 'cors'};
	return fetch(
		`${process.env.GATSBY_SOLR_API_URL}/${process.env.GATSBY_SOLR_API_SEARCH_FULL_DIR}/?q=${firstParam}`,
		options
	)
		.then(checkStatus)
		.then(parseJson);
};

export const postContactData = (data) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};
	// window.dataLayer.push({
	// 	event: 'biib_formSubmit',
	// 	eventAsset: 'submission of contact form',
	// 	eventAssetBis: '/',
	// });
	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/contact`, options).then(checkStatus).then(parseJson);
};

export const postSurveyData = (data) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};

	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/survey`, options).then(checkStatus).then(parseJson);
};

export const postCallbackServiceData = (data) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};

	return fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/callback`, options).then(checkStatus).then(parseJson);
};

export const sendForm = (data) => {
	const options = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/pdf',
		},
		mode: 'cors',
		body: data,
	};
	return fetch(`${process.env.GATSBY_ENROLLER_API_URL}/ap/v1/enroller`, options).then(checkStatus).then(parseJson);
};
