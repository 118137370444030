import {
	SUBMIT_CALLBACKSERVICE_FAILED,
	SUBMIT_CALLBACKSERVICE_REQUEST,
	SUBMIT_CALLBACKSERVICE_SUCCESS
} from '../constants';

export const submitCallbackService = data => ({
	type: SUBMIT_CALLBACKSERVICE_REQUEST,
	payload: {
		data
	}
});

export const submitCallbackServiceSuccess = info => ({
	type: SUBMIT_CALLBACKSERVICE_SUCCESS,
	payload: {
		info
	}
});

export const submitCallbackServiceFailed = error => ({
	type: SUBMIT_CALLBACKSERVICE_FAILED,
	payload: {
		error
	}
});
