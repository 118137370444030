import {combineReducers} from 'redux';
import defaultReducer from './default/default';
import overlay from './overlay/overlay';
import filterCategories from './filterCategories/filterCategories';
import initialSearch from './search/initialSearch';
import fullSearch from './search/fullSearch';
import likes from './likes/likes';
import survey from './survey/survey';
import bookmarks from './bookmarks/bookmarks';
import disclaimer from './disclaimer/disclaimer';
import userManagement from './userManagement/userManagement';
import messages from './messages/messages';
import contact from './contact/contact';
import callbackService from './callbackService/callbackService';

export default combineReducers({
	defaultReducer,
	filterCategories,
	overlay,
	fullSearch,
	survey,
	initialSearch,
	likes,
	bookmarks,
	disclaimer,
	userManagement,
	messages,
	contact,
	callbackService,
});
