/* eslint-disable no-case-declarations */
import * as Constants from '../../constants';
import {ActionBarStyled} from '../../../components/Container/Article/ArticleActions/styles';

export const initialState = {
	loading: false,
	results: [],
	bookmarksUpdated: false,
	articleBookmark: null
};

const updateBookmarks = (state, action) => {
	if (action.result.value === 'add') {
		let newResults = state.results.slice();
		newResults.splice(0, 0, {post_id: action.result.postId.toString()});
		return newResults;
	}
	if (action.result.value === 'remove') {
		return state.results.filter(item => item.post_id !== action.result.postId.toString());
	}
	return null;
};

const updateBookmarksInitial = (state, action) => {
	let allBookmarks = [];

	if (action.results && action.results.code === 'not_found') {
		return [];
	}
	if (!!state.results && state.results.length === 0) {
		return action.results.value;
	}
	if (action.results.value) {
		allBookmarks = action.results.value.map(post => {
			return post;
		});
	}
	return allBookmarks;
};

const bookmarks = (state = initialState, action) => {
	switch (action.type) {
		case Constants.GET_ALL_BOOKMARKS:
			const {userId} = action.payload;
			return {...state, userId, userloading: true};
		case Constants.ALL_BOOKMARKS_RECEIVED:
			const updatedBookmarksInit = updateBookmarksInitial(state, action);
			return {...state, results: updatedBookmarksInit, loading: false, bookmarkBeingProcessed: false};
		case Constants.POST_BOOKMARK:
		case Constants.POST_UNBOOKMARK:
			return {...state, bookmarkBeingProcessed: true};
		case Constants.BOOKMARK_RECEIVED:
			const updatedBookmarks = updateBookmarks(state, action);
			return {
				...state,
				results: updatedBookmarks,
				bookmarksUpdated: true,
				bookmarkBeingProcessed: false
			};
		case Constants.ARTICLE_BOOKMARK_RECEIVED:
			return {...state, articleBookmark: action.result};
		default:
			return state;
	}
};

export default bookmarks;
