import * as Constants from '../../constants';

const empty = [];

const filterCategories = (state = empty, action) => {
	switch (action.type) {
		case Constants.ADD_FILTER_CATEGORY:
			return [...state, action.payload.category];
		case Constants.REMOVE_FILTER_CATEGORY: {
			const {category} = action.payload;
			return [...state.filter((elem) => elem !== category)];
		}
		case Constants.REPLACE_FILTER_CATEGORIES:
			return action.payload.categories;

		case Constants.REMOVE_ALL_FILTER_CATEGORIES:
			return empty;

		default:
			return state;
	}
};

export default filterCategories;
