// helper functions

export const urlsFromState = (results = {}, facet) => {
	const oldFacetData = results[facet] || {};
	return oldFacetData.urls || [];
};

export const urlsAndResultsCountFromAction = action => {
	const {results_count: resultsCount, urls} = action.payload.results || {};
	return {resultsCount, urls};
};

export const mergeUrls = (resultCount, oldUrlsOrNone, newUrlsOrNone) => {
	// a simple version would be:
	// [...oldUrlsOrNone, ...newUrlsOrNone], but this doesn't respect the order (field 'position') of the elements
	const result = new Array(resultCount);
	for (let list of [oldUrlsOrNone, newUrlsOrNone]) {
		(list || []).forEach(item => {
			const index = item.position - 1; // because position is 1-based
			result[index] = item;
		});
	}
	return result;
};
