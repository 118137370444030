import {put, takeLatest} from 'redux-saga/effects';

import {
	SUBMIT_CALLBACKSERVICE_REQUEST,
	SUBMIT_CONTACT_REQUEST,
	SUBMIT_ENROLLER_REQUEST,
	SUBMIT_SURVEY_REQUEST,
} from '../state/constants';
import {submitContactFailed, submitContactSuccess} from '../state/actions/contact';
import {submitEnrollerFailed, submitEnrollerSuccess} from '../state/actions/enroller';
import {submitSurveyFailed, submitSurveySuccess} from '../state/actions/survey';
import {submitCallbackServiceFailed, submitCallbackServiceSuccess} from '../state/actions/callbackService';
import {postContactData, postCallbackServiceData, postSurveyData} from '../utils/fetchAPI';

function* submitContact(action) {
	const {data} = action.payload;
	try {
		yield postContactData(data);
		const info = 'Deine Kontaktanfrage wurde übermittelt.';
		yield put(submitContactSuccess(info));
		// window.dataLayer.push({
		// 	event: 'biib_formSubmit',
		// 	eventAsset: 'submission of contact form',
		// 	eventAssetBis: '/',
		// });
	} catch (err) {
		console.log('err: ', err);
		// we don't process 'err' here, instead we proceed with an error string
		const error = 'Deine Kontaktanfrage konnte leider nicht übermittelt werden.';
		yield put(submitContactFailed(error));
	}
}

function* submitSurvey(action) {
	const {data} = action.payload;
	try {
		yield postSurveyData(data);
		const info = 'Deine Umfrageergenisse wurde übermittelt.';
		yield put(submitSurveySuccess(info));
	} catch (err) {
		// we don't process 'err' here, instead we proceed with an error string
		const error = 'Deine Umfrageergebnisse konnten leider nicht übermittelt werden.';
		yield put(submitSurveyFailed(error));
	}
}

function* submitCallbackService(action) {
	const {data} = action.payload;
	try {
		yield postCallbackServiceData(data);
		const info = 'Deine Kontaktanfrage wurde übermittelt.';
		yield put(submitCallbackServiceSuccess(info));
	} catch (err) {
		// we don't process 'err' here, instead we proceed with an error string
		const error = 'Deine Kontaktanfrage konnte leider nicht übermittelt werden.';
		yield put(submitCallbackServiceFailed(error));
	}
}

function* submitEnrollerData() {
	try {
		const error = 'Vielen Dank, deine Anmeldung ist bei uns angekommen, wir melden uns zeitnah bei dir!';
		yield put(submitEnrollerSuccess(error));
	} catch (err) {
		// we don't process 'err' here, instead we proceed with an error string
		const error = 'Deine Anmeldung konnte leider nicht übermittelt werden.';
		yield put(submitEnrollerFailed(error));
	}
}

export function* watchSubmitRequests() {
	yield takeLatest(SUBMIT_CONTACT_REQUEST, submitContact);
	yield takeLatest(SUBMIT_SURVEY_REQUEST, submitSurvey);
	yield takeLatest(SUBMIT_CALLBACKSERVICE_REQUEST, submitCallbackService);
	yield takeLatest(SUBMIT_ENROLLER_REQUEST, submitEnrollerData);
}
