import * as Constants from '../constants';

// -- initial search

export const initialSearch = (searchTerm) => ({
	type: Constants.INITIAL_SEARCH_REQUEST,
	payload: {
		searchTerm,
	},
});

export const initialSearchFromSecondPage = (searchTerm) => ({
	type: Constants.INITIAL_SEARCH_REQUEST_FROM_SECOND_PAGE,
	payload: {
		searchTerm,
	},
});

export const initialSearchSuccess = (results) => ({
	type: Constants.INITIAL_SEARCH_SUCCESS,
	payload: {
		results,
	},
});

export const initialSearchFailed = (error) => ({
	type: Constants.INITIAL_SEARCH_FAILED,
	payload: {
		error,
	},
});

export const resetInitialSearch = () => ({
	type: Constants.INITIAL_SEARCH_RESET,
});

// -- full search

export const fullSearch = () => ({
	type: Constants.FULL_SEARCH_REQUEST,
});

export const fullSearchSuccess = (facet, results) => ({
	type: Constants.FULL_SEARCH_SUCCESS,
	payload: {
		facet,
		results,
	},
});

export const fullSearchFailed = (error) => ({
	type: Constants.FULL_SEARCH_FAILED,
	payload: {
		error,
	},
});

export const fullSearchFollowUp = (facet, outstandingHttpQueries) => ({
	type: Constants.FULL_SEARCH_FOLLOWUP,
	payload: {
		facet,
		outstandingHttpQueries,
	},
});

export const resetFullSearch = () => ({
	type: Constants.FULL_SEARCH_RESET,
});
