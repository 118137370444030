import {put, takeLatest} from 'redux-saga/effects';
import {
	GET_ALL_LIKES,
	ALL_LIKES_RECEIVED,
	POST_LIKE,
	LIKE_RECEIVED,
	GET_LIKE,
	ARTICLE_LIKE_RECEIVED,
	POST_UNLIKE
} from '../state/constants';
import {getAllLikes, postLike, postUnlike, getLikeById} from '../utils/fetchAPI';

function* fetchAllLikes() {
	const results = yield getAllLikes();
	yield put({type: ALL_LIKES_RECEIVED, results});
}

function* fetchLikeById(param) {
	const result = yield getLikeById(param.payload.id);
	yield put({type: ARTICLE_LIKE_RECEIVED, result});
}

function* postLikeSaga(param) {
	const res = yield postLike(param.payload.id);
	const result = {
		value: res.value,
		statusCode: res.code,
		wpId: param.payload.id
	};
	yield put({type: LIKE_RECEIVED, result});
}

function* postUnlikeSaga(param) {
	const res = yield postUnlike(param.payload.id);
	const result = {
		value: res.value,
		statusCode: res.code,
		wpId: param.payload.id
	};
	yield put({type: LIKE_RECEIVED, result});
}

export function* watchGetAllLikes() {
	yield takeLatest(GET_ALL_LIKES, fetchAllLikes);
}

export function* watchGetLikeById() {
	yield takeLatest(GET_LIKE, fetchLikeById);
}

export function* watchPostLike() {
	yield takeLatest(POST_LIKE, postLikeSaga);
}

export function* watchPostUnlike() {
	yield takeLatest(POST_UNLIKE, postUnlikeSaga);
}
