export const getAllBookmarks = async data => {
	const {userId} = data.payload;
	const newpayload = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json'
		}
	};

	const res = await fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/bookmarks/${userId}`, newpayload);
	const response = await res.json();
	return response;
};

export const postBookmark = async (userId, postId) => {
	const data = {user_id: userId.toString(), post_id: postId};

	const payload = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	};

	const res = await fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/bookmark`, payload);
	const response = await res.json();
	return response;
};

export const postUnbookmark = async (userId, postId) => {
	const data = {user_id: userId.toString(), post_id: postId.toString()};

	const payload = {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
	};

	const res = await fetch(`${process.env.GATSBY_WORDPRESS_API_URL}/ap/v1/remove-bookmark`, payload);
	const response = await res.json();
	return response;
};
