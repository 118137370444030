import {
	HIDE_SURVEY,
	SHOW_SURVEY,
	SUBMIT_SURVEY_FAILED,
	SUBMIT_SURVEY_REQUEST,
	SUBMIT_SURVEY_SUCCESS,
} from '../../constants';

import {omit} from '../../../utils/immutability';
export const initialState = {
	showSurveyCount: 0,
	surveySent: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SHOW_SURVEY: {
			return {
				...state,
			};
		}
		case HIDE_SURVEY:
			return {
				...state,
				showSurveyCount: state.showSurveyCount + 1
			};
		case SUBMIT_SURVEY_REQUEST:
			return {
				...state,
				loading: true,
			};

		case SUBMIT_SURVEY_SUCCESS: {
			// const {response} = action.payload;  // not used here
			return {
				...omit(state, ['loading']),
				surveySent: true
			};
		}

		case SUBMIT_SURVEY_FAILED: {
			// const {error} = action.payload;  // not used here
			return {
				...omit(state, ['loading']),
			};
		}
		default:
			return state;
	}
};
