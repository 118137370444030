import {HIDE_DISCLAIMER_EXTERNAL_LINK, SHOW_DISCLAIMER_EXTERNAL_LINK} from '../../constants';
import {omit} from '../../../utils/immutability';
import {KEY_TO} from './constants';

export default (state = {}, action) => {
	switch (action.type) {
		case SHOW_DISCLAIMER_EXTERNAL_LINK: {
			const {to} = action.payload;
			return {
				...state,
				[KEY_TO]: to
			};
		}
		case HIDE_DISCLAIMER_EXTERNAL_LINK:
			return omit(state, [KEY_TO]);
		default:
			return state;
	}
};
