import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas';
import reducer from './reducers';
import {saveState, loadState} from '../utils/localStorage';

const sagaMiddleware = createSagaMiddleware();

const persistLSMiddleware = (store) => (next) => (action) => {
	const result = next(action);
	const myState = store.getState();
	saveState({
		defaultReducer: myState.defaultReducer,
		likes: myState.likes,
		bookmarks: myState.bookmarks,
		filterCategories: myState.filterCategories,
		showPersonal: myState.showPersonal,
		userManagement: myState.userManagement,
		currentErrors: myState.currentErrors,
		survey: myState.survey,
	});
	return result;
};

export default () => {
	const enhancers = [];
	if (process.env.NODE_ENV === 'development') {
		if (typeof window === 'object') {
			const {__REDUX_DEVTOOLS_EXTENSION__} = window;
			if (typeof devToolsExtension === 'function') {
				enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
			}
		}
	}

	const composedEnhancers = compose(applyMiddleware(persistLSMiddleware, sagaMiddleware), ...enhancers);
	const persistedState = loadState();
	const store = createStore(reducer, persistedState, composedEnhancers);
	sagaMiddleware.run(rootSaga);
	return store;
};
