/* eslint-disable no-case-declarations */
import * as Constants from '../../constants';

export const initialState = {
	loading: false,
	results: [],
	counter: 0,
	likesUpdated: false,
	updatedCounter: 0,
	articleLike: null
};

const updateItems = (state, action) => {
	return state.results.map(item => {
		if (action.result.wpId === item.post_id) {
			return {
				...item,
				likes: action.result.value,
				liked: !item.liked
			};
		}
		return item;
	});
};

const updateItemsInitial = (state, action) => {
	// there are no results yet - use plain resultset from response
	if (state.results.length === 0) {
		return action.results;
	}
	// merge server likes with existing state-likes
	const allPosts = action.results.map(post => {
		const storedPost = state.results.find(elem => elem.post_id === post.post_id);
		if (storedPost) {
			return {
				...post,
				liked: storedPost.liked || false
			};
		}
		return post;
	});
	return allPosts;
};

const likes = (state = initialState, action) => {
	switch (action.type) {
		case Constants.GET_ALL_LIKES:
			return {...state, loading: true};
		case Constants.ALL_LIKES_RECEIVED:
			const updatedItemsInit = updateItemsInitial(state, action);
			return {...state, results: updatedItemsInit, loading: false, likeBeingProcessed: false};
		case Constants.POST_LIKE:
		case Constants.POST_UNLIKE:
			return {...state, likeBeingProcessed: true};
		case Constants.LIKE_RECEIVED:
			const updatedItems = updateItems(state, action);
			return {
				...state,
				results: updatedItems,
				likesUpdated: true,
				articleLike: action.result.value,
				likeBeingProcessed: false
			};
		case Constants.INCREMENT_LIKES:
			return {...state, counter: state.counter + 1};
		case Constants.ARTICLE_LIKE_RECEIVED:
			return {...state, articleLike: action.result};
		default:
			return state;
	}
};

export default likes;
