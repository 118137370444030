import {put, select, takeLatest} from 'redux-saga/effects';
import {navigate} from 'gatsby';
import {userNormalize} from 'normalize/user-normalize';

import {
	USER_LOGIN_REQUEST,
	USER_LOGOUT_REQUEST,
	USER_REGISTER_REQUEST,
	USER_DATA_UPDATE_REQUEST,
	USER_PASSWORD_RESET_REQUEST,
	USER_PASSWORD_SET_NEW_REQUEST,
	USER_PASSWORD_FORGOT_PW_REQUEST,
	USER_NLF_REQUEST,
	USER_NLF_UNSET,
	USER_PZN_REQUEST,
	USER_PZN_UNSET,
	USER_GEMEINSAM_REQUEST,
	USER_DOUBLE_OPT_IN,
} from '../state/constants';
import {
	postLoginUser,
	getLogoutUser,
	postResetPassword,
	postSetNewPassword,
	postForgotPassword,
	postUserData,
	postRegisterUser,
	postDOI,
	postNLF,
	postTokenUser,
} from '../utils/fetchAPI';
import {
	resetPasswordFailed,
	resetPasswordSuccess,
	requestPasswordSuccess,
	userDataUpdateSuccess,
	userDataUpdateFailed,
	userLoginFailed,
	userLoginSuccess,
	userLogoutFailed,
	userLogoutSuccess,
	userRegisterFailed,
	userRegisterSuccess,
	setNLFSuccess,
	setNLFFailed,
	unsetNLFSuccess,
	unsetNLFFailed,
	setPZNSuccess,
	setPZNFailed,
	unsetPZNSuccess,
	unsetPZNFailed,
	setGemeinsamFailed,
	setGemeinsamSuccess,
} from '../state/actions/userManagement';

// helper for select()

export const getUser = (state) => state.userManagement.user;

// sagas

function* loginUser(action) {
	const {credentials} = action.payload;

	const state = yield select();
	const {
		userManagement: {nlfObject},
	} = state;
	let localNLFDoesExist = false;
	// check if there is local nlf information and merge that into user object
	if (nlfObject.nlfVal1 && nlfObject.nlfVal2) {
		localNLFDoesExist = true;
	}

	try {
		let result = yield postLoginUser(credentials);
		const parsedResult = JSON.parse(result);
		if (localNLFDoesExist === true) {
			const data = {
				UserId: parsedResult.UserId,
				nlfVal1: nlfObject.nlfVal1,
				nlfVal2: nlfObject.nlfVal2,
			};
			const userData = yield postNLF(data);
			result = {
				...parsedResult,
				nlf: userData.value.nlf,
			};
			result = JSON.stringify(result);
		}
		const errorLoginFailed = {text: 'Benutzername und / oder Passwort ungültig.', button: 'OK'};
		if (JSON.parse(result).Error) {
			yield put(userLoginFailed(errorLoginFailed));
		} else {
			const parsed = JSON.parse(result);
			const pspUser = yield postTokenUser(credentials);
			parsed.token = pspUser.token;
			const normalize = userNormalize(parsed);
			yield put(userLoginSuccess(normalize));
		}
	} catch (error) {
		const serverError = {text: 'Anmeldeserver ist nicht erreichbar', button: 'OK'};
		yield put(userLoginFailed(serverError));
	}
}

function* logoutUser() {
	try {
		yield getLogoutUser();
		navigate('/');
		yield put(userLogoutSuccess());
	} catch (error) {
		yield put(userLogoutFailed(error));
	}
}

function* updateUserNLF(action) {
	const {nlf} = action.payload;
	const state = yield select();
	let info = null;
	if (nlf >= 14 && !!state.userManagement.user) {
		info = {
			message:
				'Vielen Dank, deine Angaben wurden gespeichert. Zukünftig können wir dir individuelle, auf dich zugeschnittene Inhalte anzeigen.',
			button: 'OK',
		};
	} else if (nlf >= 14) {
		info = {
			button: 'Jetzt anmelden',
			message:
				'Um deine Angaben zu speichern, melde dich bitte mit deinen persönlichen Zugangsdaten an oder erstelle einen Mitglieder-Account. Mithilfe deiner Angaben können wir dir zukünftig individuelle, auf dich zugeschnittene Inhalte anzeigen.',
		};
		navigate('/login');
	}

	try {
		yield put(setNLFSuccess(info));
		// yield put({type: USER_NLF_REQUEST, payload: nlf});
	} catch (error) {
		yield put(setNLFFailed());
	}
}

function* unsetUserNLF() {
	try {
		yield put(unsetNLFSuccess());
	} catch (error) {
		yield put(unsetNLFFailed());
	}
}

function* userDoubleOptIn(action) {
	const {credentials} = action.payload;
	try {
		postDOI(credentials);
		yield null;
	} catch (error) {
		yield null;
	}
}

function* updateUserPZN(action) {
	try {
		if (typeof window !== 'undefined') {
			if (action.payload.pzn.lang && action.payload.pzn.lang.length > 0 && action.payload.pzn.lang !== 'de') {
				navigate(
					`/spezial/international/${action.payload.pzn.slug}/${action.payload.pzn.product}-${action.payload.pzn.lang}`
				);
			} else if (action.payload.pzn.chapter && action.payload.pzn.chapter.length > 1) {
				navigate(`/service/ms-behandlung/${action.payload.pzn.slug}#${action.payload.pzn.chapter}`);
			} else if (action.payload.pzn.ar === true) {
				navigate(`/service/ms-behandlung/${action.payload.pzn.slug}/ar`);
			} else {
				navigate(`/service/ms-behandlung/${action.payload.pzn.slug}`);
			}
		}

		yield put(setPZNSuccess());
	} catch (error) {
		console.log('error: ', error);
		yield put(setPZNFailed());
	}
}

function* setGemeinsam() {
	try {
		yield put(setGemeinsamSuccess());
	} catch (error) {
		yield put(setGemeinsamFailed());
	}
}

function* unsetUserPZN() {
	try {
		if (typeof window !== 'undefined') {
			if (window.location.pathname.includes('ms-behandlung')) {
				navigate('/service/ms-behandlung');
			}
		}
		yield put(unsetPZNSuccess());
	} catch (error) {
		yield put(unsetPZNFailed());
	}
}

function* registerUser(data) {
	let errorMsg = {message: 'Es gab Probleme. Bitte versuche es später noch einmal', button: 'OK'};
	try {
		const response = yield postRegisterUser(data);

		const res = JSON.parse(response);
		if (res.Result) {
			window.dataLayer.push({
				event: 'biib_registerSuccess',
				eventAsset: 'submission of registration to mein.MS-life.de',
				eventAssetBis: '/',
			});
			navigate('/registrierung-bestaetigen');
			yield put(userRegisterSuccess(response));
		} else {
			if (res.UserName && res.Email) {
				errorMsg = {message: 'Dieser Benutzername und Email Adresse werden bereits verwendet.', button: 'OK'};
			} else if (res.Email) {
				errorMsg = {
					message: 'Dieser Benutzername und Email Adresse werden bereits verwendet.',
					button: 'OK',
				};
			} else if (res.UserName) {
				errorMsg = {message: 'Dieser Benutzername wird bereits verwendet.', button: 'OK'};
			}
			yield put(userRegisterFailed(errorMsg));
		}
	} catch (error) {
		yield put(userRegisterFailed(error));
	}
}

function* updateUserData(action) {
	const {data} = action.payload;

	// merge 'old' data with 'new' data from action
	// todo: check if this is necessary, i.e. if backend accepts incomplete data set and merges on its own
	//       (in that case we need at least the user-id from user)
	const user = yield select(getUser);
	console.log('user: ', user);

	const mergedData = {
		...user,
		...data,
	};

	let info = null;
	const response = yield postUserData(mergedData);
	if (response.response.message === 'Ungültige(r) Parameter: agreement') {
		const error = {message: 'Du musst der Datenschutzrichtlinie zustimmen um fortfahren zu können'};
		yield put(userDataUpdateFailed(error));
	} else if (response.response.includes('Missing')) {
		const error = {message: 'Du musst der Datenschutzrichtlinie zustimmen um fortfahren zu können'};
		yield put(userDataUpdateFailed(error));
	} else if (response.response.includes('Success')) {
		info = {message: 'Deine Daten wurden erfolgreich gespeichert.', button: 'OK'};
		yield put(userDataUpdateSuccess(info, mergedData));
	} else {
		const error = {message: response.response};
		yield put(userDataUpdateFailed(error));
	}
}

function* resetPassword(action) {
	const {credentials} = action.payload;
	const user = yield select(getUser);
	const mergedData = {
		...user,
		...credentials,
	};
	try {
		const response = yield postResetPassword(mergedData);
		const result = JSON.parse(response);
		if (result.Result === 'Received') {
			navigate('/');
			const info = {
				message:
					'Vielen Dank, dein Passwort wurde gespeichert. Du kannst dich ab jetzt mit deinen neuen Benutzerdaten anmelden',
				button: 'OK',
			};
			yield put(resetPasswordSuccess(info));
		} else {
			navigate('/');
			const error = {
				message: 'Es gab Probleme. Bitte versuche es später noch einmal',
				button: 'OK',
			};
			yield put(resetPasswordFailed(error));
		}
	} catch (error) {
		yield put(resetPasswordFailed(error));
	}
}

function* setNewPassword(action) {
	const {credentials} = action.payload;

	try {
		const response = yield postSetNewPassword(credentials);
		const result = JSON.parse(response);
		if (result.Result === 'Success') {
			const info = {
				message:
					'Vielen Dank, dein Passwort wurde gespeichert. Du kannst dich ab jetzt mit deinen neuen Benutzerdaten anmelden',
				button: 'OK',
			};
			yield put(resetPasswordSuccess(info));
		} else {
			const error = {
				message: 'Es gab Probleme. Bitte versuche es später noch einmal',
				button: 'OK',
			};
			yield put(resetPasswordFailed(error));
		}
	} catch (error) {
		yield put(resetPasswordFailed(error));
	}
}

function* forgotPassword(action) {
	const {credentials} = action.payload;

	try {
		const response = yield postForgotPassword(credentials);
		const result = JSON.parse(response);
		if (result.Result === 'Success') {
			const info = {
				message:
					'Wir haben Dir eine Email mit weiteren Anweisungen zum Zurücksetzen Deines Passwortes geschickt.',
				button: 'OK',
			};
			yield put(requestPasswordSuccess(info));
		} else {
			yield put(resetPasswordFailed(response));
		}
	} catch (error) {
		yield put(resetPasswordFailed(error));
	}
}

export function* watchUserLogin() {
	yield takeLatest(USER_LOGIN_REQUEST, loginUser);
}

export function* watchUserLogout() {
	yield takeLatest(USER_LOGOUT_REQUEST, logoutUser);
}

export function* watchUserRegister() {
	yield takeLatest(USER_REGISTER_REQUEST, registerUser);
}

export function* watchUserDataUpdate() {
	yield takeLatest(USER_DATA_UPDATE_REQUEST, updateUserData);
}

export function* watchUserPasswordReset() {
	yield takeLatest(USER_PASSWORD_RESET_REQUEST, resetPassword);
}

export function* watchUserPasswordSetNew() {
	yield takeLatest(USER_PASSWORD_SET_NEW_REQUEST, setNewPassword);
}

export function* watchUserNLFUpdate() {
	yield takeLatest(USER_NLF_REQUEST, updateUserNLF);
}

export function* watchUserNLFUnset() {
	yield takeLatest(USER_NLF_UNSET, unsetUserNLF);
}

export function* watchUserPZNUpdate() {
	yield takeLatest(USER_PZN_REQUEST, updateUserPZN);
}

export function* watchUserPZNUnset() {
	yield takeLatest(USER_PZN_UNSET, unsetUserPZN);
}

export function* watchUserGemeinsam() {
	yield takeLatest(USER_GEMEINSAM_REQUEST, setGemeinsam);
}

export function* watchUserDoubleOptIn() {
	yield takeLatest(USER_DOUBLE_OPT_IN, userDoubleOptIn);
}

export function* watchUserForgotPW() {
	yield takeLatest(USER_PASSWORD_FORGOT_PW_REQUEST, forgotPassword);
}
