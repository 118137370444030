/**
 *  Utility functions related to immutability,
 *  i.e. create clones of objects adding/removing keys
 */

/**
 * Returns a clone of argument obj without the keys in keysToOmit.
 *
 * Similar to lodash's omit(), but simpler, as it compares keys, not paths.
 *
 * @param {Object}    obj          object to be cloned
 * @param {String[]}  keysToOmit   determine which keys are omitted
 * @returns {Object}
 */
export const omit = (obj, keysToOmit = []) =>
	keysToOmit.length
		? new ModifyEntriesAndReassembleToObject(obj).filter((key, value) => !keysToOmit.includes(key)).result()
		: obj;

// TODO adapt js-doc to this new class-based version
/**
 * modifies object by passing filter- and map-callbacks,
 * useful for converting, filtering
 *
 * @param {Object}     obj          object to be cloned and modified
 * @param {Function}   callbacks    array of callback functions that filter and map/modify (see unit tests)
 * @returns {Object}
 */
export class ModifyEntriesAndReassembleToObject {
	constructor(obj) {
		this.keyValueList = Object.entries(obj);
		this.originalKeyValueList = this.keyValueList;
		this.original = obj;
	}

	filter(callback) {
		this.keyValueList = this.keyValueList.filter(pair => callback(...pair));
		return this;
	}

	map(callback) {
		this.keyValueList = this.keyValueList.map(pair => {
			const newPair = callback(...pair);
			// todo: needs a unit test
			return pairsAreEqual(newPair, pair) ? pair : newPair;
		});
		return this;
	}

	result() {
		if (this.keyValueList === this.originalKeyValueList) {
			// nothing has changed
			return this.original;
		}
		return this.keyValueList.reduce(
			// recreate object from array of pairs
			(result, [key, value]) => ({
				...result,
				[key]: value
			}),
			{}
		);
	}
}

export const pairsAreEqual = (pair1, pair2) => pair1[0] === pair2[0] && pair1[1] === pair2[1];
