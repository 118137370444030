// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-konto-bestaetigen-js": () => import("./../../../src/pages/konto-bestaetigen.js" /* webpackChunkName: "component---src-pages-konto-bestaetigen-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-mindset-mentor-js": () => import("./../../../src/pages/mindset-mentor.js" /* webpackChunkName: "component---src-pages-mindset-mentor-js" */),
  "component---src-pages-neues-passwort-beantragen-js": () => import("./../../../src/pages/neues-passwort-beantragen.js" /* webpackChunkName: "component---src-pages-neues-passwort-beantragen-js" */),
  "component---src-pages-neues-passwort-js": () => import("./../../../src/pages/neues-passwort.js" /* webpackChunkName: "component---src-pages-neues-passwort-js" */),
  "component---src-pages-nutritionmanager-js": () => import("./../../../src/pages/nutritionmanager.js" /* webpackChunkName: "component---src-pages-nutritionmanager-js" */),
  "component---src-pages-registrieren-js": () => import("./../../../src/pages/registrieren.js" /* webpackChunkName: "component---src-pages-registrieren-js" */),
  "component---src-pages-registrierung-bestaetigen-js": () => import("./../../../src/pages/registrierung-bestaetigen.js" /* webpackChunkName: "component---src-pages-registrierung-bestaetigen-js" */),
  "component---src-pages-suche-js": () => import("./../../../src/pages/suche.js" /* webpackChunkName: "component---src-pages-suche-js" */),
  "component---src-pages-therapieinformationen-js": () => import("./../../../src/pages/therapieinformationen.js" /* webpackChunkName: "component---src-pages-therapieinformationen-js" */),
  "component---src-pages-verify-user-js": () => import("./../../../src/pages/verify-user.js" /* webpackChunkName: "component---src-pages-verify-user-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-dossier-js": () => import("./../../../src/templates/dossier.js" /* webpackChunkName: "component---src-templates-dossier-js" */),
  "component---src-templates-new-post-js": () => import("./../../../src/templates/newPost.js" /* webpackChunkName: "component---src-templates-new-post-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

